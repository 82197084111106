import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Aligner = makeShortcode("Aligner");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "meaning-full-truth-solved",
      "style": {
        "position": "relative"
      }
    }}>{`Meaning Full Truth (Solved)`}<a parentName="h1" {...{
        "href": "#meaning-full-truth-solved",
        "aria-label": "meaning full truth solved permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p><em parentName="p">{`nothing which we are to perceive in this world equals`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`the power of your intense fragility:whose texture`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`compels me with the colour of its countries,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`rendering death and forever with each breathing`}</em>{`  `}</p>
    <p>{`Koans are the keys to a mystery which extends beyond me, beyond what I think or identify with or can readily understand. There is an intimacy in all things which cannot be said, an intense fragility right down in the heart of your life which compels you to keep looking, to keep breathing, to keep searching even in the face of a death to which you know you must eventually come.`}</p>
    <p>{`This is not morbid, it is the root of real celebration. To live each moment fully, knowing that the breath which goes in may not come out: this is what it means to be awake. The `}<a parentName="p" {...{
        "href": "/learn/module-0/conversation"
      }}>{`!Gwi people`}</a>{` Paul Myburgh lived with have a beautiful greeting they offer to one another each morning, at the dawn of a new day which is both exactly what it is, and a total echo of eternity. They say, `}<em parentName="p">{`tsamkwa /tge?`}</em>{`, or "Are your eyes nicely open?".`}</p>
    <p>{`The Module 3 koan begins `}<Link to="https://libraryofbabel.info/bookmark.cgi?truthmeans" mdxType="Link">{`here`}</Link>{`. The prize this week is called `}<Link to="https://ethblock.art/view/106" mdxType="Link">{`"Candid Syzygy"`}</Link>{`. The word "syzygy" (along with "rhythms") is the longest word without vowels in English. A syzygy is a roughly straight-line configuration of three or more celestial bodies in a gravitational system, an alignment of the stars from which you are made: those points of past light which guide us into a common future.`}</p>
    <Aligner center mdxType="Aligner">
      <Image style={{
        "width": "60%"
      }} src="/images/koans/candid_syzygy.png" alt="Candid Syzygy" mdxType="Image" />
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      